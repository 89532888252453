.container, .layer, .text-box, .spacer {
    display: block;
}

.container {
    position: relative;
    width: 100%; /* Adjust based on your needs */
    height: 40px; /* Adjust based on the height of your text box and button */
}

.layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.5s ease; /* Smooth transition for the sliding effect */
}

#layer1 {
    z-index: 1;
}

#layer2 {
    z-index: 2; /* Layer 2 starts above Layer 1 */
    cursor: pointer;
}

/* New base styles for the text box and spacer button */
.text-box {
    width: 66%; /* Example width; adjust as needed */
    flex-grow: 2;
    height: 90%; /* Example height; adjust as needed */
    /*padding: 5px 10px; !* Add some padding inside the text box *!*/
}

.spacer {
    width: 33%; /* Should match the text box width */
    flex-grow: 1;
    height: 90%; /* Should match the text box height */
    /*visibility: hidden; !* Keeps the spacer button layout but not visible *!*/
}
/*.spacer {*/
/*    visibility: hidden; !* Hides the spacer button but keeps its space *!*/
/*}*/

.slide {
    transform: translateX(-50%) scaleX(0%); /* Slides Layer 2 upwards to reveal Layer 1 */
}
