.groupflex {
    height: auto;
    width: 600px;
    display: flex;
    margin: auto;
    margin-top: 300px;
    flex-direction: row;
    justify-content: space-evenly;
    /* border-style: solid;
    border-width: 1px;
    border-color: black; */
    background-color: white;
    margin-bottom: 20px;
}
.groupnamecolumn {
    display: flex;
    position: relative;
    flex-direction: column;
}
.userflex {
    height: auto;
    width: 600px;
    display: flex;
    margin: auto;
    margin-top: 300px;
    flex-direction: row;
    justify-content: space-evenly;
    /* border-style: solid;
    border-width: 1px;
    border-color: black; */
    background-color: white;
    margin-bottom: 20px;
}
