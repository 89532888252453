.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  /*background-color: #222;*/
  height: 150px;
  padding: 20px;
  color: black;
  background-color: black;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

html, body {
  overflow: auto;
}
html, body{min-height:100%;}
body{height:100vh;}
